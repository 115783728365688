<script lang="ts">
    export let image: string
    export let alt: string
    export let size: string = '5'
</script>

<section>
    <img src={image} {alt} style={`width: ${size}rem; height: ${size}rem;`} />
</section>

<style lang="scss">
    section {
        background: var(--component-background, transparent);

        img {
            vertical-align: middle;
            border-radius: 50%;

            margin: 1.5rem 0;
            max-width: 100%;
            border: 0;
        }
    }
</style>
