<script lang="ts">
    export let height: number
</script>

{#if height}
    <hr style="margin: {height}rem 0" />
{:else}
    <hr />
{/if}

<style lang="scss">
    hr {
        background: var(--color-grey);
        width: 100%;
    }
</style>
