<script lang="ts">
    export let dataStore: Link_File
    export let first: boolean = false

    import Button from '@cucumber/ui/components/buttons/button.svelte'
    import LinkComponent from '../buttons/link.svelte'
    import { getDirectusAssetLink } from '@cucumber/ui/helpers/string'

    $: linkProps = {
        icon: dataStore?.icon,
        name: dataStore?.name,
        type: dataStore?.type,
        url: dataStore?.url,
        target: dataStore?.target,
        label: dataStore?.label,
    }

    $: filePath = getDirectusAssetLink(dataStore?.file?.filename_disk)
</script>

{#if dataStore?.status == 'published'}
    <section name={dataStore?.name} class={[`${dataStore?.position}`, `${dataStore?.display}`, 'link-file'].join(' ')} data-widget={dataStore.__typename} class:first>
        <a href={filePath} target="_blank">
            {#if dataStore?.display == 'button'}
                <Button>
                    <LinkComponent {...linkProps} />
                </Button>
            {:else}
                <LinkComponent {...linkProps} />
            {/if}
            {#if dataStore?.display == 'banner'}
                <img src="/assets/images/keyboard_arrow_right.svg" alt="keyboard-arrow-right" />
            {/if}
        </a>
    </section>
{/if}

<style lang="scss">
    a {
        width: 100%;
        height: 100%;

        display: grid;
        grid-template-columns: 1fr max-content;
        width: 100%;
        height: 100%;
        align-items: center;
        align-content: center;
        text-decoration: none;
    }
    section {
        background: var(--component-background, transparent);
        width: fit-content;
        max-width: 100%;
        width: 100%;

        display: grid;
        align-items: center;

        :global(button) {
            display: grid;
            align-items: center;
        }
        :global(img) {
            width: 1rem;
            border: unset;
            border-radius: unset;
            color: var(--color-accent);
            display: unset;
            padding-left: 0;
        }

        &.banner {
            color: var(--color-white);
            background-color: var(--color-accent);
            grid-template-columns: 1fr 3rem;
            grid-template-columns: 1fr max-content;
            grid-template-rows: minmax(4.5rem, min-content);
            margin: 0.75rem 0;

            &.right {
                :global(section) {
                    grid-column: 1/2;
                }
                :global(a) {
                    padding-right: 1rem;
                }
            }

            &.centre {
                :global(a) {
                    padding-right: 1rem;
                    :global(section) {
                        grid-column: 1/3;
                        grid-row: 1;
                    }
                }
            }

            &.left {
                :global(a) {
                    padding-right: 1rem;
                    padding-left: 0.8rem;
                }

                :global(i) {
                    padding: unset;
                }
            }

            :global(span) {
                grid-row: 1;
                grid-column: 2;
            }

            :global(i) {
                border: unset;
                border-radius: unset;
                color: unset;
                display: unset;
            }

            :global(a) {
                color: var(--color-white);
            }

            :global(a) {
                white-space: normal;
            }
        }

        &.button {
            :global(a) {
                white-space: normal;
            }

            :global(button) {
                display: grid;
                align-items: center;
            }
            :global(i) {
                border: unset;
                border-radius: unset;
                color: var(--color-accent);
                display: unset;
                padding-left: 0;
            }
        }

        &.default {
            :global(i) {
                color: var(--color-link-blue) !important;
            }
            :global(a) {
                white-space: normal;
                color: var(--color-link-blue);
            }
            :global(div) {
                grid-template-columns: unset;
            }

            :global(button) {
                display: grid;
                align-items: center;
            }
            :global(i) {
                border: unset;
                border-radius: unset;
                display: unset;
                padding-left: 0;
            }
        }

        &.left {
            justify-content: start;
            justify-items: start;
            :global(a) {
                text-align: left;
            }
        }

        &.right {
            justify-content: end;
            justify-items: end;
            :global(a) {
                text-align: right;
            }
        }

        &.centre {
            justify-content: center;
            justify-items: center;
            :global(a) {
                text-align: center;
            }
        }
    }
</style>
