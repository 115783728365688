<script lang="ts">
    export let dataStore: Builder_Block
    export let components: Map<string, SvelteComponent>

    export let first: boolean = false
</script>

{#if dataStore?.status == 'published'}
    <section name={dataStore?.name} data-widget={dataStore.__typename} class:first>
        <div>{dataStore?.title}</div>
        <div class="body" style={`grid-template-columns: ${dataStore?.split}fr ${6 - (dataStore?.split ? dataStore?.split : 0)}fr;`}>
            <div class="left">
                {#if dataStore?.left}
                    {#each dataStore?.left as item}
                        {#if item?.collection}
                            <svelte:component this={components.get(item?.collection)} {...item?.item} />
                        {/if}
                    {/each}
                {/if}
            </div>

            {#if dataStore?.right}
                <div class="right">
                    {#each dataStore?.right as item}
                        {#if item?.collection}
                            <svelte:component this={components.get(item?.collection)} {...item?.item} />
                        {/if}
                    {/each}
                </div>
            {/if}
        </div>
    </section>
{/if}

<style lang="scss">
    section {
        background: var(--component-background, transparent);
        max-width: var(--bp-max);

        div {
            padding-bottom: 1rem;
        }
        .body {
            display: grid;
            grid-template-columns: 4fr 2fr;
            grid-gap: var(--grid-gap);

            &:not(.fullWidth) {
                grid-template-columns: 4fr 2fr;
            }
        }
    }
</style>
