<script lang="ts">
    export let dataStore: Story
    export let first: boolean = false

    import { getDirectusAssetLink } from '@cucumber/ui/helpers/string'
    import Avatar from '@cucumber/ui/components/avatar.svelte'
</script>

{#if dataStore?.status == 'published'}
    <section name={dataStore?.name} class:full={!dataStore?.body?.length} class={[dataStore?.position].join(' ')} data-widget={dataStore.__typename} class:first>
        <div class={['thumbnail', dataStore?.position].join(' ')}>
            <Avatar image={getDirectusAssetLink(dataStore?.thumbnail?.filename_disk)} alt="thumbnail" size="20" />
        </div>
        <div>
            <p>{dataStore?.body}</p>
        </div>
    </section>
{/if}

<style lang="scss">
    section {
        background: var(--component-background, transparent);
        max-width: var(--bp-max);

        display: grid;
        position: relative;
        align-items: center;
        column-gap: 2rem;
        row-gap: 1rem;
        padding: 0 2rem;

        &:not(.full) {
            grid-template-columns: max-content 1fr;

            &.right {
                grid-template-columns: 1fr max-content;
            }
        }

        .thumbnail {
            grid-row: 1;
            align-self: center;

            &.left {
                grid-column: 1;
            }

            &.right {
                grid-column: 2;
            }
        }
    }
</style>
