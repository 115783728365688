<script lang="ts">
    export let title: Maybe<string>
    export let body: Maybe<string>
    export let image: Maybe<string>
    export let link: Maybe<Link>
    export let link_file: Maybe<Link_File>
    export let name: Maybe<string>
    export let display: Maybe<string>

    import { getDirectusAssetLink } from '@cucumber/ui/helpers/string'
    import LinkWidget from '@cucumber/ui/components/widgets/link.svelte'
    import LinkFile from '@cucumber/ui/components/widgets/link-file.svelte'
</script>

<section {name} class={[`${link?.display}`].join(' ')}>
    {#if display == 'default' && title && body && image && (link || link_file)}
        <div class="title-body-image-link">
            {#if image}
                <img src={getDirectusAssetLink(image)} alt={title} />
            {/if}
            <div class="content">
                {#if title}
                    <h2>{title}</h2>
                {/if}
                {#if body}
                    <p>{body}</p>
                {/if}
                {#if link?.display == 'default' || link_file?.display == 'default'}
                    {#if link}
                        <LinkWidget dataStore={link} />
                    {:else if link_file}
                        <LinkFile dataStore={link_file} />
                    {/if}
                {/if}
            </div>
        </div>
        {#if link?.display == 'banner' || link_file?.display == 'banner'}
            <div class="link-banner">
                {#if link}
                    <LinkWidget dataStore={link} />
                {:else if link_file}
                    <LinkFile dataStore={link_file} />
                {/if}
            </div>
        {:else if link?.display == 'button' || link_file?.display == 'button'}
            <div class="link-button">
                {#if link}
                    <LinkWidget dataStore={link} />
                {:else if link_file}
                    <LinkFile dataStore={link_file} />
                {/if}
            </div>
        {/if}
    {:else if display == 'default' && title && body && (link || link_file)}
        <div class="title-body-link">
            <div class="content">
                {#if title}
                    <h2>{title}</h2>
                {/if}
                {#if body}
                    <p>{body}</p>
                {/if}
                {#if link?.display == 'default' || link_file?.display == 'default'}
                    {#if link}
                        <LinkWidget dataStore={link} />
                    {:else if link_file}
                        <LinkFile dataStore={link_file} />
                    {/if}
                {/if}
            </div>
        </div>
        {#if link?.display == 'banner' || link_file?.display == 'banner'}
            <div class="link-banner">
                {#if link}
                    <LinkWidget dataStore={link} />
                {:else if link_file}
                    <LinkFile dataStore={link_file} />
                {/if}
            </div>
        {:else if link?.display == 'button' || link_file?.display == 'button'}
            <div class="link-button">
                {#if link}
                    <LinkWidget dataStore={link} />
                {:else if link_file}
                    <LinkFile dataStore={link_file} />
                {/if}
            </div>
        {/if}
    {:else if display == 'default' && body && (link || link_file)}
        <div class="body-link">
            <div class="content">
                {#if title}
                    <h2>{title}</h2>
                {/if}
                {#if body}
                    <p>{body}</p>
                {/if}
                {#if link?.display == 'default' || link_file?.display == 'default'}
                    {#if link}
                        <LinkWidget dataStore={link} />
                    {:else if link_file}
                        <LinkFile dataStore={link_file} />
                    {/if}
                {/if}
            </div>
            {#if link?.display == 'banner' || link_file?.display == 'banner'}
                <div class="link-banner">
                    {#if link}
                        <LinkWidget dataStore={link} />
                    {:else if link_file}
                        <LinkFile dataStore={link_file} />
                    {/if}
                </div>
            {:else if link?.display == 'button' || link_file?.display == 'button'}
                <div class="link-button">
                    {#if link}
                        <LinkWidget dataStore={link} />
                    {:else if link_file}
                        <LinkFile dataStore={link_file} />
                    {/if}
                </div>
            {/if}
        </div>
    {/if}
</section>

<style lang="scss">
    section {
        display: grid;
        position: relative;
        height: 100%;
        grid-template-rows: 1fr min-content;

        .title-body-image-link {
            display: grid;
            grid-template-rows: min-content 1fr;
            align-content: center;

            .content {
                display: grid;
                height: 100%;
                align-content: center;
                padding: var(--padding-tile);
            }

            img {
                width: 100%;
                height: 10rem;
                object-fit: cover;
            }
            p {
                max-height: var(--bp-mobile);
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 1.3;
                transition: 'max-height' 0.3s;
            }
        }

        .title-body-link {
            height: 100%;
            display: grid;
            grid-template-rows: min-content 1fr;
            align-content: center;

            .content {
                display: grid;
                height: 100%;
                align-content: center;
                padding: 3rem;
                padding: var(--padding-tile);
            }
        }

        .body-link {
            height: 100%;
            .content {
                padding: var(--padding-tile);
            }
            p {
                max-height: var(--bp-mobile);
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 1.5;
                transition: 'max-height' 0.3s;
            }
        }

        .link-banner {
            width: 100%;

            :global(section) {
                margin-bottom: 0;
            }

            :global(a) {
                color: var(--color-white);
            }

            :global(i) {
                color: var(--color-white);
            }
        }

        .link-button {
            margin: 0.75rem 0;

            :global(a) {
                color: var(--color-white);
            }
        }

        div {
            :global(button) {
                padding: 1rem 2rem;
            }
        }
    }
</style>
