<script lang="ts">
    export let dataStore: Maybe<Walkthrough_Links>
    export let components: Map<string, SvelteComponent>
    export let first: boolean = false

    import Button from '@cucumber/ui/components/buttons/button.svelte'
</script>

<section name={dataStore?.name} data-widget={dataStore?.__typename} class:first>
    {#if dataStore?.prev}
        <div class="prev">
            <Button style="outlined">
                <a href={dataStore?.prev.name} sveltekit:noscroll>PREV</a>
            </Button>
        </div>
    {/if}

    {#if dataStore?.next}
        <div class="next">
            <Button style="contained">
                <a href={dataStore?.next.name} sveltekit:noscroll>NEXT</a>
            </Button>
        </div>
    {/if}
</section>

<style lang="scss">
    section {
        display: grid;
        grid-template-columns: 1fr 1fr;

        padding: 1rem;
        margin: -1rem 0 1rem;
        background: var(--color-grey);

        :global(button) {
            width: unset !important;
        }

        a {
            font-size: var(--font-size);
            width: fit-content;
            height: 100%;
            color: var(--color-accent);
        }

        .prev {
            justify-self: start;
            grid-column: 1;
        }

        .next {
            justify-self: end;
            grid-column: 2;
        }
    }
</style>
