<script lang="ts">
    export let selectedDates: string | Date | Date[] | undefined
    export let showPastDate: boolean = false
    export let datePickerOnChange: boolean = false
    export let maxDateRangeAllowed: number

    import Flatpickr from 'svelte-flatpickr'
    import * as dateFns from 'date-fns'

    let mode: 'single' | 'multiple' | 'range' | 'time' | undefined = 'range'

    let flatpickrOptions: any

    $: if (selectedDates) updateFlatPickrOptions()

    function updateFlatPickrOptions() {
        flatpickrOptions = {
            element: '#my-picker',
            mode: mode,
            enableTime: false,
            altInput: false,
            wrap: true,
            altFormat: 'd/m/Y',
            minDate: showPastDate ? '' : 'today',
            maxDate: showPastDate ? 'today' : '',
            disable: [
                function (date: Date) {
                    if (maxDateRangeAllowed) {
                        if ((selectedDates as Date[]).length == 1) {
                            const rangeStart = (selectedDates as Date[])[0]
                            return (
                                dateFns.addDays(rangeStart, maxDateRangeAllowed).setHours(0, 0, 0, 0) < date.setHours(0, 0, 0, 0) ||
                                dateFns.addDays(rangeStart, -1 * maxDateRangeAllowed).setHours(0, 0, 0, 0) > date.setHours(0, 0, 0, 0)
                            )
                        } else return false
                    } else return false
                },
            ],
            onChange: function () {
                datePickerOnChange = true
            },
        }
    }
</script>

<svelte:head>
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/flatpickr/dist/flatpickr.min.css" />
    <link rel="stylesheet" type="text/css" href="https://npmcdn.com/flatpickr/dist/themes/material_green.css" />
</svelte:head>

<div class="datepicker-wrapper">
    <Flatpickr options={flatpickrOptions} bind:value={selectedDates} element="#my-picker">
        <div class="flatpickr relative" id="my-picker">
            <input
                type="text"
                placeholder="Select Date.."
                data-input
                class="w-full pl-4 pr-10 py-3 leading-none rounded-lg shadow-sm
            focus:outline-none focus:shadow-outline text-gray-600 font-medium"
            />

            <div class="absolute top left">
                <img src="/assets/images/calendar.svg" alt="calendar" />
            </div>
        </div>
    </Flatpickr>
</div>

<style lang="scss">
    .datepicker-wrapper {
        .flatpickr {
            max-width: var(--bp-600);

            input {
                cursor: pointer;
            }

            .absolute {
                position: absolute;
                width: 2rem;
                height: 2rem;

                cursor: pointer;

                &.top {
                    top: 0.5rem;
                }

                &.left {
                    left: 1rem;
                }

                img {
                    width: 2rem;
                    height: 2.3rem;
                }
            }
        }

        :global(input[type='text']) {
            border: 1px solid var(--color-accent);
            color: var(--color-accent);
            padding: 0.6rem 1rem 0.6rem 4rem;
        }
    }
</style>
