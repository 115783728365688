<script lang="ts">
    export let auction: UpcomingAuction

    import * as dateFns from 'date-fns'
    import { AuctionStatus } from '_config/enums/auction-status.enum'
    import { ActivityStatus } from '_config/enums/activity-status.enum'
    import Button from '@cucumber/ui/components/buttons/button.svelte'
    import { getDisplayDateTime } from 'helpers/auctions.helper'
    import { goto } from '$app/navigation'
    import { isAuthenticated } from '_auth/stores/auth.store'
    import { login_url } from '_auth/stores/provider.store'
    import { displayModal } from '@cucumber/ui/stores/ui.store'
    import { activeAuction, requestAuctionsFromPage } from 'stores/auction.store'
    import { isTodaysDate } from 'helpers/date.helper'
    import { activityStatusMapping, auctionStatusMapping } from '_config/constants/status-mapping'
    import { auctionUrl } from 'stores/url.store'
    import { auctionTypeIconMapping } from '_config/constants/auction-item-logo-mapping'
    import { navHistory } from '@cucumber/ui/stores/ui.store'

    let registeredAccount: string
    let redirectLocation: string

    function updateRegisteredAccount() {
        setTimeout(() => (registeredAccount = auction?.bidAccounts ? (auction?.bidAccounts[0]?.accountName as string) : ''))
    }

    function handleClick() {
        $activeAuction = {
            auctionId: auction.id,
            isRegistered: registeredAccount ? registeredAccount != '' : false,
        }

        if ($requestAuctionsFromPage == 'result') redirectLocation = `/results/auction/${auction.id.toString()}`
        else redirectLocation = `/auction/${auction.id.toString()}`

        $navHistory.push(location.pathname)
        return goto(redirectLocation)
    }

    function handleRegisterClick() {
        $activeAuction = {
            auctionId: auction.id,
            isRegistered: registeredAccount ? registeredAccount != '' : false,
        }

        if (!$isAuthenticated) {
            redirectLoggedIn('register-modal')
        } else {
            $displayModal = 'register-modal'
        }
    }

    function handleConnectClick() {
        if (auction.isPrivateAuctionWatcher || (auction?.bidAccounts && auction?.bidAccounts?.length > 0)) {
            goto(`${$auctionUrl}/auctions/${auction.id}/`)
        } else {
            $activeAuction = {
                auctionId: auction.id,
                isRegistered: registeredAccount ? registeredAccount != '' : false,
            }

            if (!$isAuthenticated) {
                redirectLoggedIn('connect-modal')
            } else {
                $displayModal = 'connect-modal'
            }
        }
    }

    function redirectLoggedIn(modal: string) {
        localStorage.setItem('after_action_return_path', location.pathname)
        localStorage.setItem('after_action_open_modal', modal)
        localStorage.setItem('after_action_active_auction_info', JSON.stringify($activeAuction))

        return goto($login_url)
    }

    $: showConnectButton = [AuctionStatus.Ready, AuctionStatus.InProgress, AuctionStatus.Paused].includes(auction?.auctionStatus)
    $: showRegisterButton = ![AuctionStatus.Finished, AuctionStatus.Closed, AuctionStatus.Cancelled].includes(auction?.auctionStatus)
    $: if ($isAuthenticated && auction?.bidAccounts && (auction?.bidAccounts![0]?.accountName as string)) updateRegisteredAccount()
</script>

{#if auction}
    <section>
        <div
            class="auction-detail"
            class:today={isTodaysDate(dateFns.parseISO(auction.startsAt + 'Z'))}
            class:finished={AuctionStatus[auction?.auctionStatus]?.toLowerCase() == 'finished'}
            on:click={handleClick}
        >
            <div class="icon">
                {#if auction.stockTypes.length > 1}
                    <img src={auctionTypeIconMapping.get('Other')} alt={auction.id.toString()} title={auction.id.toString()} />
                {:else}
                    <img src={auctionTypeIconMapping.get(auction.stockTypes[0]) ?? auctionTypeIconMapping.get('Other')} alt={auction.id.toString()} title={auction.id.toString()} />
                {/if}
            </div>
            <div class="title"><h3>{auction.title}</h3></div>
            <div class="lot-info">
                <div class="auction-date">{getDisplayDateTime(dateFns.parseISO(auction.startsAt + 'Z'))}</div>
                <div>
                    {#if auction.numberOfLots > 0}
                        {auction.numberOfLots}

                        {#if auction.numberOfLots > 1}
                            Lots
                        {:else}
                            Lot
                        {/if}
                    {/if}
                </div>
                <div>{auction.auctionFormatLabel}</div>
            </div>
            <div class="auction-status">
                <div class="stock-types">{auction.stockTypes?.join(', ')}</div>
                {#if [AuctionStatus.Ready, AuctionStatus.InProgress, AuctionStatus.Paused, AuctionStatus.Finished, AuctionStatus.Cancelled, AuctionStatus.Closed].includes(auction?.auctionStatus)}
                    <div class={[AuctionStatus[auction?.auctionStatus]?.toLowerCase()].join(' ').toLowerCase()}>
                        <span class="dot" /><span>{auctionStatusMapping.get(auction?.auctionStatus)}</span>
                    </div>
                {:else if [ActivityStatus.Postponed, ActivityStatus.DateChanged].includes(auction?.activityStatus)}
                    <div class={[ActivityStatus[auction?.activityStatus]?.toLowerCase()].join(' ').toLowerCase()}>
                        <span class="dot" />{activityStatusMapping.get(auction?.activityStatus)}
                    </div>
                {:else}
                    <div />
                {/if}
            </div>
        </div>
        <div class="action">
            {#if $requestAuctionsFromPage == 'result'}
                <Button label="View Result" style="outlined" on:click={handleClick} />
            {:else if registeredAccount != undefined}
                <div>
                    Registered with account
                    <span>{registeredAccount}</span>
                </div>
                {#if showConnectButton}
                    <Button label="Connect" style="contained" on:click={handleConnectClick} />
                {/if}
            {:else if showConnectButton}
                <Button label="Connect" style="contained" on:click={handleConnectClick} />
            {:else if showRegisterButton}
                <Button label="Register" style="outlined" on:click={handleRegisterClick} disabled={!!auction.isPrivateAuctionWatcher} />
            {/if}
        </div>
    </section>
{/if}

<style lang="scss">
    section {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: 1fr 0.2fr;
        align-items: center;

        box-shadow: var(--box-shadow);
        border-radius: 0.5rem;
        border-radius: 5px;

        padding: 2rem;
        font-size: var(--font-size);

        @media (max-width: 700px) {
            grid-template-columns: unset;
            grid-template-rows: repeat(4, min-content);
            grid-gap: 0.3rem;
            padding: 1.5rem;
        }

        .auction-detail {
            display: grid;
            grid-template-columns: min-content 1fr;
            align-items: center;
            cursor: pointer;
            grid-gap: 1rem;

            .icon {
                padding: 0 1rem 0 0;
                grid-row: 1/4;

                @media (max-width: 700px) {
                    grid-row: 1;
                    grid-column: 1;
                }

                img {
                    @media (min-width: 320px) {
                        width: 4.5rem;
                    }

                    @media (min-width: 600px) {
                        width: 5.5rem;
                        height: 5.5rem;
                    }

                    @media (min-width: 1024px) {
                        width: 6.5rem;
                        height: 6.5rem;
                    }
                }
            }

            .title {
                grid-row: 1;
                grid-column: 2;

                width: 100%;
                font-size: 2rem;
                font-weight: var(--font-weight-bold);
                color: var(--color-secondary);

                height: 100%;

                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 3;

                h3 {
                    display: inline;
                    color: unset;
                    padding-bottom: 0;
                    font-weight: unset;
                }

                @media (max-width: 700px) {
                    grid-row: 1;
                    grid-column: 2/3;
                    font-size: 1.8rem;
                }
            }

            .lot-info {
                display: grid;
                grid-template-columns: max-content 0.75fr max-content;
                justify-items: center;
                grid-row: 2;
                grid-column: 2;

                @media (max-width: 700px) {
                    grid-template-columns: 1fr max-content;
                    grid-column: 1/3;
                    justify-items: unset;
                }

                @media (min-width: 701px) and (max-width: 1024px) {
                    grid-template-columns: 1fr 0.5fr 0.5fr;
                    justify-items: unset;
                }

                .auction-date {
                    font-size: 1.8rem;
                    padding: 0;
                    @media (max-width: 700px) {
                        font-size: 1.6rem;
                    }
                }
            }

            .auction-status {
                padding: 0;

                font-size: 1.6rem;
                font-weight: var(--font-weight-bold);

                @media (max-width: 700px) {
                    grid-column: 1/3;
                }

                div {
                    &.ready {
                        --var-status-dot-color: var(--auctionstatus-green);
                        --var-status-text-color: var(--auctionstatus-green);
                    }
                    &.inprogress,
                    &.paused {
                        --var-status-dot-color: var(--auctionstatus-orange);
                        --var-status-text-color: var(--auctionstatus-orange);
                    }

                    &.finished,
                    &.closed {
                        --var-status-dot-color: var(--auctionstatus-grey);
                        --var-status-text-color: var(--auctionstatus-grey);
                    }

                    &.cancelled,
                    &.postponed,
                    &.datechanged {
                        --var-status-dot-color: var(--auctionstatus-red);
                        --var-status-text-color: var(--auctionstatus-grey);
                    }

                    color: var(--var-status-text-color);

                    .dot {
                        background-color: var(--var-status-dot-color);

                        height: 1rem;
                        width: 1rem;
                        border-radius: 50%;
                        display: inline-block;

                        margin: 0 0.5rem 0 0;
                    }
                }

                .stock-types {
                    text-transform: uppercase;
                    font-weight: var(--font-weight-light);
                    font-size: 1.2rem;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 1;
                }
            }

            &.today:not(.finished) {
                .title {
                    font-weight: var(--font-weight-bold);
                }

                .lot-info {
                    .auction-date {
                        font-weight: var(--font-weight-bold);
                    }
                }
            }
        }

        .action {
            width: 14.3rem;
            text-align: center;
            div {
                display: grid;
                color: var(--color-accent);

                word-wrap: anywhere;

                span {
                    font-weight: bold;
                    color: var(--color-secondary);
                }
            }
            @media (max-width: 700px) {
                grid-row: 4;
                width: unset;

                :global(section) {
                    width: unset;
                }
            }
        }
    }
</style>
