<script lang="ts">
    export let dataStore: Maybe<Title>
    export let first: boolean = false

    $: title = `<${dataStore?.size}>${dataStore?.value}</${dataStore?.size}>`
</script>

{#if title && dataStore?.status == 'published'}
    <div class={dataStore?.position} name={dataStore?.name} data-widget={dataStore.__typename} class:first>
        {@html title}
    </div>
{/if}

<style lang="scss">
    div {
        background: var(--component-background, transparent);

        &.first {
            margin-top: -2.2rem;
        }

        &.left {
            text-align: left;
        }

        &.right {
            text-align: right;
        }

        &.centre {
            text-align: center;
        }
    }
</style>
