import * as dateFns from 'date-fns'

export function getAuctionsByDate(auctionList: Maybe<UpcomingAuction[]>, auctionDateFormat: AuctionDateFormat, needSortByDate: boolean = true): AuctionDetailByDate[] {
    if (!auctionList || auctionList?.some(a => !a)) return []

    if (needSortByDate) auctionList = auctionList?.sort((a: UpcomingAuction, b: UpcomingAuction) => compare(new Date(a.startsAt), new Date(b.startsAt)))

    const auctionDates: AuctionDate[] = getUniqueDates(auctionList, auctionDateFormat)

    const auctionByDateList: AuctionDetailByDate[] = auctionDates.flatMap((auctionDate: AuctionDate) => {
        const data = auctionList?.filter(item => dateFns.format(dateFns.parseISO(item.startsAt + 'Z'), 'yyyy/MM/dd') === auctionDate.auctionDate)
        if (!data) return []

        return {
            auctionDateDisplay: auctionDate.auctionDateDisplay,
            auctions: data,
        }
    })

    return auctionByDateList
}

function getUniqueDates(auctionList: UpcomingAuction[], auctionDateFormat: AuctionDateFormat): AuctionDate[] {
    const uniqueDateList: AuctionDate[] = []

    auctionList.map(item => {
        if (uniqueDateList.find((d: AuctionDate) => d.auctionDate == dateFns.format(dateFns.parseISO(item.startsAt + 'Z'), 'yyyy/MM/dd'))) return

        uniqueDateList.push({
            auctionDate: dateFns.format(dateFns.parseISO(item.startsAt + 'Z'), 'yyyy/MM/dd'),
            auctionDateDisplay: getDisplayDate(dateFns.parseISO(item.startsAt + 'Z'), auctionDateFormat),
        })
    })

    return uniqueDateList
}

function getFormattedDate(value: Date): string {
    return dateFns.format(value, 'yyyy/MM/dd')
}

function getDisplayDate(value: Date, auctionDateFormat: AuctionDateFormat): string {
    if (getFormattedDate(value) === getFormattedDate(new Date())) return dateFns.format(value, auctionDateFormat.today)
    else return dateFns.format(value, auctionDateFormat.upcoming)
}

export function getDisplayDateTime(value: Date): string {
    if (getFormattedDate(value) === getFormattedDate(new Date())) return dateFns.format(value, "hh:mm a '(TODAY)'")
    else return dateFns.format(value, "dd MMM '-' hh:mm a")
}

function compare(a: Date, b: Date) {
    if (a > b) return 1
    else if (a < b) return -1
    else return 0
}
