<script lang="ts">
    export let dataStore: Team
    export let first: boolean = false

    import TeamMemberWidget from '@cucumber/ui/components/widgets/team-member.svelte'
</script>

{#if dataStore?.status == 'published'}
    <section name={dataStore?.name} data-widget={dataStore.__typename} class:first>
        {#if dataStore?.items}
            {#each dataStore?.items as team}
                <TeamMemberWidget dataStore={team} />
            {/each}
        {/if}
    </section>
{/if}

<style lang="scss">
    section {
        background: var(--component-background, transparent);
        max-width: var(--bp-400);

        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(var(--tile-width, 25rem), 1fr));
        grid-gap: 2rem;

        height: fit-content;
    }
</style>
