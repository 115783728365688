<script lang="ts">
    export let dataStore: Team_Member
    export let first: boolean = false

    import PhoneNumberWidget from '@cucumber/ui/components/widgets/phone-number.svelte'
    import LinkWidget from '@cucumber/ui/components/widgets/link.svelte'
    import { getDirectusAssetLink } from '@cucumber/ui/helpers/string'
    import Icon from '@cucumber/ui/components/icon.svelte'
    import Avatar from '@cucumber/ui/components/avatar.svelte'
</script>

{#if dataStore?.status == 'published'}
    <section name={dataStore?.name} data-widget={dataStore.__typename} class:first>
        <Icon icon="location_on">
            <span><a href={`https://maps.google.com/?q=${dataStore?.address}`} target="_blank">{dataStore?.address}</a></span>
        </Icon>
        <hr />
        <div class="picture">
            <Avatar image={getDirectusAssetLink(dataStore?.picture?.filename_disk)} alt={`${dataStore?.last_name}, ${dataStore?.first_name}`} />
            <div>
                <span>
                    <h3>
                        <b>{`${dataStore?.last_name}, ${dataStore?.first_name}`}</b>
                    </h3>
                </span>
                <span>{dataStore?.title}</span>
            </div>
        </div>
        <div>
            {#if dataStore?.phones}
                {#each dataStore?.phones as item}
                    <PhoneNumberWidget dataStore={item} />
                {/each}
            {/if}
        </div>
        <div>
            {#if dataStore?.emails}
                {#each dataStore?.emails as item}
                    <LinkWidget dataStore={item} />
                {/each}
            {/if}
        </div>
    </section>
{/if}

<style lang="scss">
    section {
        background: var(--component-background, transparent);
        max-width: var(--bp-400);

        display: grid;
        grid-template-rows: repeat(4, max-content);

        padding: 0 1rem;

        hr {
            margin: 0 0 0.5rem 0;
        }

        .picture {
            display: grid;
            grid-template-columns: max-content 1fr;
            align-items: center;

            div {
                display: grid;
                grid-template-rows: repeat(2, max-content);

                &:first-child {
                    align-self: center;
                }
                span {
                    padding: 0 1rem;

                    h3 {
                        margin: unset;
                    }
                }
            }
        }
    }
</style>
