<script lang="ts">
    export let dataStore: Address
    export let first: boolean = false

    import PhoneNumberWidget from '@cucumber/ui/components/widgets/phone-number.svelte'
</script>

{#if dataStore?.status == 'published'}
    <section name={dataStore?.name} data-widget={dataStore.__typename} class:first>
        <h3>{dataStore?.title}</h3>
        <p>{dataStore?.address}</p>

        {#if dataStore?.phones}
            {#each dataStore?.phones as item}
                <PhoneNumberWidget dataStore={item} />
            {/each}
        {/if}
    </section>
{/if}

<style lang="scss">
    section {
        background: var(--component-background, transparent);
        max-width: var(--bp-400);

        height: fit-content;
        margin: var(--margin);
        padding: var(--padding);
        box-shadow: var(--box-shadow);

        :global(section) {
            padding: 0;
        }
    }
</style>
