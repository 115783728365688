<script lang="ts">
    export let icon: Maybe<string>
    export let name: Maybe<string>
    export let type: Maybe<string>
    export let url: Maybe<string>
    export let target: Maybe<string>
    export let label: Maybe<string>

    import Icon from '@cucumber/ui/components/icon.svelte'
</script>

<section class:withIcon={icon} {name}>
    {#if icon}
        {#if type == 'email'}
            <a href={`mailto:${url}`} target={target != '_self' ? target : null}>{label}</a>
        {:else}
            <Icon {icon} />
            <a href={url} target={target != '_self' ? target : null}>{label}</a>
        {/if}
    {:else if type == 'email'}
        <a href={`mailto:${url}`} target={target != '_self' ? target : null}>{label}</a>
    {:else}
        <a href={url} target={target != '_self' ? target : null}>{label}</a>
    {/if}
</section>

<style lang="scss">
    section {
        background: var(--component-background, transparent);
        grid-row: 1;

        a {
            height: 100%;
            color: var(--color-accent);
            font-size: var(--font-size);
        }
    }
</style>
