<script lang="ts">
    export let dataStore: Maybe<Tile_Sets>

    import { Splide, SplideSlide } from '@splidejs/svelte-splide'
    import TileWidget from '@cucumber/ui/components/widgets/tile.svelte'
    import '@splidejs/svelte-splide/css'

    const mainOptions = {
        type: 'slide',
        perPage: 8,
        perMove: 1,
        pagination: false,
        height: 'auto',
        width: '100vw',
        rewind: false,
        arrows: dataStore?.tiles && dataStore?.tiles?.length > 8 ? true : false,
        breakpoints: {
            1500: {
                perPage: 7,
                arrows: true,
            },
            1300: {
                perPage: 6,
                arrows: true,
            },
            1100: {
                perPage: 5,
                arrows: true,
            },
            900: {
                perPage: 4,
                arrows: true,
            },
            600: {
                perPage: 3,
                arrows: true,
            },
            400: {
                perPage: 2,
                arrows: true,
            },
            350: {
                perPage: 1,
                arrows: true,
            },
        },
    }
</script>

<div class="carousel-container">
    <Splide options={mainOptions}>
        {#if dataStore?.tiles}
            {#each dataStore?.tiles as tile}
                {#if tile?.tile_id}
                    <SplideSlide>
                        <TileWidget dataStore={tile?.tile_id} />
                    </SplideSlide>
                {/if}
            {/each}
        {/if}
    </Splide>
</div>

<style lang="scss">
    .carousel-container {
        padding: 0 2rem;

        :global(div) {
            position: static;
        }

        :global(li) {
            padding: unset;
        }

        :global(img) {
            width: 100%;
        }

        :global(.splide__arrow--next) {
            right: 0;

            @media (max-width: 340px) {
                right: 3rem;
            }
        }

        :global(.splide__arrow--prev) {
            left: 0;
        }
    }
</style>
