<script lang="ts">
    export let dataStore: Maybe<Question>
    export let first: boolean = false

    import DrawerComponent from '@cucumber/ui/components/drawer.svelte'
</script>

{#if dataStore?.answer && dataStore?.status == 'published'}
    <section name={dataStore?.name} data-widget={dataStore.__typename} class:first>
        <DrawerComponent title={dataStore?.question}>
            <div><p>{@html dataStore?.answer}</p></div>
        </DrawerComponent>
    </section>
{/if}

<style lang="scss">
    section {
        background: var(--component-background, transparent);
        overflow: hidden;

        div {
            overflow-x: auto;
        }
    }
</style>
