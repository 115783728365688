<script lang="ts">
    export let title: Maybe<string>
    export let image: Maybe<string>
    export let link: Maybe<Link>
    export let link_file: Maybe<Link_File>

    import { getDirectusAssetLink } from '@cucumber/ui/helpers/string'
    $: filePath = getDirectusAssetLink(link_file?.file?.filename_disk)
</script>

<section name="tile-image-only">
    {#if link}
        <div>
            <a href={link?.url} target={link?.target}>
                <img src={getDirectusAssetLink(image)} alt={title ?? ''} />
            </a>
        </div>
    {/if}
    {#if link_file}
        <div>
            <a href={filePath} target="_blank">
                <img src={getDirectusAssetLink(image)} alt={title ?? ''} />
            </a>
        </div>
    {/if}
    {#if !link && !link_file}
        <div>
            <img src={getDirectusAssetLink(image)} alt={title ?? ''} />
        </div>
    {/if}
</section>

<style lang="scss">
    section {
        width: 100%;
        height: 100%;
        display: grid;

        a {
            width: 100%;
            height: 100%;
            display: contents;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
</style>
