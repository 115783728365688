<script lang="ts">
    export let videoId: Maybe<string>
</script>

<section>
    {#if videoId}
        <iframe
            src={`https://www.youtube.com/embed/${videoId}?controls=0`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
        />
    {/if}
</section>

<style lang="scss">
    section {
        background: var(--component-background, transparent);
        line-height: 0;
        iframe {
            width: 100%;
            height: 100%;
            aspect-ratio: 16 / 9;
        }
    }
</style>
