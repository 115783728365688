<script lang="ts">
    export let dataStore: Maybe<Tile>

    import YoutubeVideoComponent from '@cucumber/ui/components/widgets/parts/video-youtube.svelte'
    import FlipableImageComponent from '@cucumber/ui/components/widgets/parts/image-flipable.svelte'
    import TileImageOnly from '@cucumber/ui/components/widgets/parts/tile-image-only.svelte'
    import TileDefault from '@cucumber/ui/components/widgets/parts/tile-default.svelte'
    import TileImageAsBackground from '@cucumber/ui/components/widgets/parts/tile-image-as-background.svelte'

    $: imageProps = {
        title: dataStore?.title,
        body: dataStore?.body,
        image: dataStore?.image?.filename_disk,
        link: dataStore?.link,
        link_file: dataStore?.link_file,
        name: dataStore?.name,
        display: dataStore?.display,
    }
</script>

{#if dataStore?.status == 'published'}
    {#if dataStore?.display == 'default'}
        <TileDefault {...imageProps} />
    {/if}

    {#if dataStore?.display == 'flipable'}
        <FlipableImageComponent {...imageProps} />
    {/if}

    {#if dataStore?.display == 'image'}
        <TileImageOnly {...imageProps} />
    {/if}

    {#if dataStore?.display == 'video'}
        <YoutubeVideoComponent videoId={dataStore?.video} />
    {/if}

    {#if dataStore?.display == 'background'}
        <TileImageAsBackground {...imageProps} dark_mode={dataStore?.dark_mode} />
    {/if}
{/if}
