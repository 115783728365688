<script lang="ts">
    export let dataStore: Phone_Number
    export let first: boolean = false

    import Icon from '@cucumber/ui/components/icon.svelte'
</script>

{#if dataStore?.value && dataStore?.status == 'published'}
    <section class={[`${dataStore?.position}`].join(' ')} name={dataStore?.name} data-widget={dataStore.__typename} class:first>
        <Icon icon={dataStore?.icon}>
            {dataStore?.value}
        </Icon>
    </section>
{/if}

<style lang="scss">
    section {
        background: var(--component-background, transparent);
        max-width: var(--bp-800);

        display: grid;

        &.left {
            justify-content: start;
        }

        &.right {
            justify-content: end;
        }

        &.centre {
            justify-content: center;
        }
    }
</style>
