<script lang="ts">
    export let dataStore: Spacer
    export let first: boolean = false
</script>

<section data-widget={dataStore.__typename} class:first>
    <i style={`height: ${dataStore?.spacer}rem`} />
</section>

<style lang="scss">
    section {
        background: var(--component-background, transparent);

        i {
            width: 100%;

            display: grid;
            margin: 0 auto;
        }
    }
</style>
