<script lang="ts">
    export let dataStore: Banner
    export let first: boolean = false

    import { getDirectusAssetLink } from '@cucumber/ui/helpers/string'
</script>

{#if dataStore?.status == 'published'}
    <section name={dataStore?.name} style="height: {dataStore?.height}rem;" class={[dataStore?.type, dataStore?.width].join(' ')} data-widget={dataStore.__typename} class:first>
        {#if dataStore?.url}
            <a href={dataStore?.url}>
                <img src={getDirectusAssetLink(dataStore?.image?.filename_disk)} alt={dataStore?.title} />
            </a>
        {:else}
            <a href={dataStore?.url}>
                <img class={[dataStore?.type, dataStore?.width].join(' ')} src={getDirectusAssetLink(dataStore?.image?.filename_disk)} alt={dataStore?.title} />
            </a>
        {/if}
        <div style="text-align: {dataStore?.text_alignment};">
            <h1>
                {#if dataStore?.title != null}
                    {dataStore?.title}
                {/if}
            </h1>
            <h2>
                {#if dataStore?.sub_title != null}
                    {dataStore?.sub_title}
                {/if}
            </h2>
        </div>
    </section>
{/if}

<style lang="scss">
    section {
        background: var(--component-background, transparent);

        position: relative;
        color: white;
        max-width: 100%;
        max-height: 100%;
        overflow: hidden;

        display: grid;

        grid-template-rows: 1fr;
        grid-template-columns: 1fr;

        div {
            // padding: 2rem 3rem 3rem 3rem;
            color: white;
            width: 100%;
            grid-row: 1;
            grid-column: 1;
            pointer-events: none;
        }

        h1 {
            padding-bottom: 0;
        }

        h1,
        h2 {
            color: #ffffff;
            margin: 0;
        }

        &.contain {
            img {
                position: absolute; // so the text can sit on top of the image and the image can still be fixed
                top: 0;
                bottom: 0;
                margin: auto;
                object-fit: cover;
                height: 100%;
            }

            // h2 {
            //     max-height: 20rem;
            //     display: -webkit-box;
            //     -webkit-box-orient: vertical;
            //     overflow: hidden;
            //     text-overflow: ellipsis;
            //     font-weight: 500;
            // }

            // viewport sizes recieved from: https://docs.microsoft.com/en-us/windows/apps/design/layout/screen-sizes-and-breakpoints-for-responsive-design
            // mobile viewport
            @media (min-width: 320px) and (max-width: 640px) {
                div {
                    padding: 2rem 3rem 3rem 3rem;
                }
                h1 {
                    font-size: 2.4rem; // 2rem
                    line-height: 1.3;
                }
                h2 {
                    font-size: 1.6rem;
                    // -webkit-line-clamp: 4;
                    line-height: 1.3;
                }
            }
            // tablet viewport
            @media (min-width: 641px) and (max-width: 1007px) {
                div {
                    padding: 2rem 3rem 3rem 3rem;
                }
                h1 {
                    font-size: 2.7rem;
                    line-height: 1.3;
                }
                h2 {
                    font-size: 1.7rem;
                    -webkit-line-clamp: 3;
                    line-height: 2;
                }
            }
            // desktop viewport
            @media (min-width: 1008px) {
                div {
                    padding: 3rem 5rem 6rem 5rem;
                }
                h1 {
                    font-size: 3rem;
                    line-height: 1.3;
                }
                h2 {
                    font-size: 2rem;
                    -webkit-line-clamp: 3;
                    line-height: 2;
                }
            }
        }

        // change font-size for scale viewports
        &.scale {
            height: unset !important;

            div {
                padding: 2rem 6rem 6rem 6rem;
            }

            img {
                position: relative;
                height: 100%;
            }
            div {
                position: absolute; // so the text can sit on top of the image and the image can still scale
            }
            h1 {
                font-size: 3rem;
            }
            h2 {
                margin-bottom: 0;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                font-weight: 500;
            }
            // mobile viewport
            @media (min-width: 320px) and (max-width: 640px) {
                overflow: hidden;
                h1 {
                    font-size: 3rem;
                }
                h2 {
                    font-size: 2.8rem;
                    max-height: 20rem;
                    -webkit-line-clamp: 4;
                }
            }
            // tablet viewport
            @media (min-width: 641px) and (max-width: 1007px) {
                h1 {
                    font-size: 3rem;
                }
                h2 {
                    font-size: 2.8rem;
                    max-height: 60rem;
                    -webkit-line-clamp: 7;
                }
            }
            // desktop viewport
            @media (min-width: 1008px) {
                h1 {
                    font-size: 3rem;
                }
                h2 {
                    font-size: 2.8rem;
                    max-height: 60rem;
                    -webkit-line-clamp: 8;
                }
            }
        }

        &.full {
            img {
                width: 100%;
            }
        }

        &.content {
            img {
                width: 100%;
            }
            @media (min-width: 320px) and (max-width: 640px) {
                div {
                    padding: 2rem 3rem 3rem 3rem;
                }
                h1 {
                    font-size: 2.4rem; // 2rem
                    line-height: 1.3;
                }
                h2 {
                    font-size: 1.6rem;
                    // -webkit-line-clamp: 4;
                    line-height: 2;
                }
            }
            // tablet viewport
            @media (min-width: 641px) and (max-width: 1007px) {
                div {
                    padding: 2rem 3rem 3rem 3rem;
                }
                h1 {
                    font-size: 2.7rem;
                    line-height: 1.3;
                }
                h2 {
                    font-size: 1.7rem;
                    -webkit-line-clamp: 3;
                    line-height: 2;
                }
            }
            // desktop viewport
            @media (min-width: 1008px) {
                div {
                    padding: 3rem 6rem 6rem 6rem;
                }
                h1 {
                    font-size: 3rem;
                    line-height: 1.3;
                }
                h2 {
                    font-size: 2rem;
                    -webkit-line-clamp: 3;
                    line-height: 2;
                }
            }
        }

        a {
            width: 100%;

            &:hover {
                text-decoration: none;
            }
        }
    }
</style>
