<script lang="ts">
    export let dataStore: Rich_Text
    export let first: boolean = false

    $: content = typeof dataStore?.value == 'string' ? dataStore?.value?.replaceAll('contactus_name', 'name') : dataStore?.value
</script>

{#if dataStore?.value && dataStore?.status == 'published'}
    <section name={dataStore?.name} class="rich-text" data-widget={dataStore.__typename} class:first>
        {@html content}
    </section>
{/if}

<style lang="scss">
    section {
        background: var(--component-background, transparent);
        max-width: var(--bp-max);

        font-weight: normal;
        display: grid;

        &.rich-text {
            :global(table) {
                border-spacing: 0;
                max-width: 100vw;
                overflow-x: auto;
                padding: 0;
                white-space: nowrap;
                width: 100%;
                font-size: 1.6rem;
                height: unset !important;

                @media (max-width: 1000px) {
                    display: inline-block;
                    margin-right: -11rem;
                }
            }

            :global(thead) {
                background-color: var(--color-tertiary);
                border-collapse: collapse;
                border-radius: var(--border-radius);
                color: var(--color-bg);
                margin: 0;
                padding: 0;
            }
            :global(thead),
            :global(tbody) {
                width: 100%;
                max-width: 100%;
            }

            :global(table td) {
                padding: 0.4rem 0.8rem;
                text-align: left;
                line-height: 3;
                border-bottom: 1px solid var(--color-grey);
            }

            :global(table tr) {
                background-color: white;
                text-align: left;
            }

            :global(table th) {
                text-align: left;
            }
            :global(img) {
                max-width: 100%;
                height: auto;
            }

            //contact-us
            :global(.container .row) {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
                grid-gap: 8rem;

                @media (max-width: 1300px) {
                    :global(iframe) {
                        width: 100%;
                    }
                    :global(.form-control) {
                        width: 100% !important;
                    }
                }
                @media (max-width: 700px) {
                    grid-template-columns: unset;
                    grid-gap: unset;
                }
            }

            :global(form) {
                padding: 1.5rem 0;
            }

            :global(form label) {
                font-size: var(--font-size);
            }

            :global(form div:not(.label-row)) {
                display: grid;
                grid-gap: 1rem;
            }

            :global(form div:not(.label-row) input) {
                margin-bottom: 1rem;
            }

            :global(form div:not(.label-row) button.primary) {
                color: var(--color-white);
            }

            :global(form div .label-row) {
                display: grid;
                grid-template-columns: max-content max-content;
            }

            :global(form div .action-button) {
                display: grid;
                grid-template-columns: max-content max-content;
            }
            :global(p) {
                color: var(--font-secondary);
            }
            :global(a) {
                color: var(--color-link-blue);
            }

            // button styling
            :global(button) {
                border: 0.2rem solid var(--color-white);
                border-radius: 0.5rem;
                width: 12rem;
                font-weight: unset;
            }
            :global(.contained) {
                --background-color: var(--color-accent);
                --color: var(--color-white);
                --border: 0.2rem solid var(--color-accent);
            }
            :global(.contained:hover) {
                background-color: var(--color-accent);
            }

            :global(.outlined) {
                background-color: var(--color-white);
                color: var(--color-accent) !important;
                border: 0.2rem solid var(--color-accent);
            }
            :global(.outlined span) {
                color: var(--color-accent);
            }
        }
    }
</style>
