<script lang="ts">
    export let dataStore: Maybe<Tile_Sets>

    import TileWidget from '@cucumber/ui/components/widgets/tile.svelte'
    import TileSetCarousel from 'components/widgets/tile-sets/tile-sets-carousel.svelte'
</script>

{#if dataStore?.status == 'published'}
    <section name={dataStore?.name} class={['tile_set', dataStore?.name, dataStore?.grid_size].join(' ')}>
        {#if dataStore?.grid_size == 'small'}
            <TileSetCarousel {dataStore} />
        {:else if dataStore?.tiles}
            {#each dataStore?.tiles as tile}
                {#if tile?.tile_id}
                    <TileWidget dataStore={tile?.tile_id} />
                {/if}
            {/each}
        {/if}
    </section>
{/if}

<style lang="scss">
    section {
        background: var(--component-background, transparent);

        display: grid;
        grid-gap: var(--grid-gap);
        justify-content: space-between;

        grid-template-columns: repeat(auto-fit, minmax(26rem, 1fr));

        margin-bottom: 3rem;
        overflow: hidden;

        &.large {
            height: min-content;
            grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));

            @media (max-width: 1024px) {
                grid-auto-flow: row;
            }
            @media (max-width: 460px) {
                grid-template-columns: 1fr;
            }
        }

        &.small {
            height: min-content;
            max-width: 100vw;
            display: grid;
            grid-auto-flow: column;

            :global(img) {
                object-fit: contain;
            }

            &::-webkit-scrollbar {
                width: 0;
                height: 0;
                display: none;
            }

            :global(section) {
                @media (max-width: 400px) {
                    width: 100%;
                    height: 100%;
                }
                //     @media (min-width: 401px) and (max-width: 600px) {
                //         width: 10rem;
                //         height: 10rem;
                //     }
            }

            // :global(img) {
            //     @media (max-width: 600px) {
            //         width: 10rem;
            //         height: auto;
            //     }
            // }
        }
    }
</style>
