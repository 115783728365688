<script lang="ts">
    export let dataStore: Millicast
    import VideoLiveStream from 'modules/millicast/video-livestream.svelte'
    import RichTextWidget from '@cucumber/ui/components/widgets/rich-text.svelte'

    $: richTextProps = {
        name: dataStore?.name,
        value: dataStore?.body,
        status: dataStore?.status as string,
    }
</script>

{#if dataStore?.status == 'published'}
    {#if dataStore.body}
        <section name={dataStore?.name}>
            <div class={['millicast', dataStore?.asset_position].join(' ')}>
                {#if dataStore?.streamId && dataStore?.accountId && dataStore?.name}
                    <VideoLiveStream account={dataStore?.accountId} stream={dataStore?.streamId} name={dataStore?.name} />
                {/if}
            </div>
            <div class="rich-text">
                <RichTextWidget dataStore={richTextProps} />
            </div>
        </section>
    {/if}

    {#if !dataStore.body}
        <section name={dataStore?.name} class="livestream-only">
            <div class={['millicast', dataStore?.asset_position].join(' ')}>
                {#if dataStore?.streamId && dataStore?.accountId && dataStore?.name}
                    <VideoLiveStream account={dataStore?.accountId} stream={dataStore?.streamId} name={dataStore?.name} />
                {/if}
            </div>
        </section>
    {/if}
{/if}

<style lang="scss">
    section {
        background: var(--component-background, transparent);
        max-width: var(--bp-max);

        display: grid;
        position: relative;
        align-items: center;

        grid-template-rows: 2;

        .millicast {
            line-height: 0;
        }
    }

    @media (min-width: 600px) {
        section {
            grid-template-columns: 1fr 1fr;
            grid-gap: 4rem;

            .millicast {
                line-height: 0;
                grid-row: 1;

                &.left {
                    grid-column: 1;
                }

                &.right {
                    grid-column: 2;
                }
            }

            .rich-text {
                margin-bottom: 4rem;
            }
        }

        .livestream-only {
            grid-template-columns: 1fr;
            margin: unset;

            .millicast {
                &.right {
                    grid-column: 1;
                }
            }
        }
    }
</style>
