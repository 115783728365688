<script lang="ts">
    export let dataStore: Maybe<Faq>
    export let first: boolean = false

    import QuestionWidget from '@cucumber/ui/components/widgets/question.svelte'
</script>

{#if dataStore && dataStore?.status == 'published'}
    <section name={dataStore?.name} data-widget={dataStore.__typename} class:first>
        {#if dataStore?.questions}
            {#each dataStore?.questions as question}
                <QuestionWidget dataStore={question?.question_id} />
            {/each}
        {/if}
    </section>
{/if}

<style lang="scss">
    section {
        background: var(--component-background, transparent);

        display: grid;
        grid-gap: 2rem;
        padding-bottom: 2rem;

        :global(a) {
            color: var(--color-link-blue);
        }

        :global(i) {
            color: var(--color-black) !important;
        }

        :global(h3) {
            font-weight: 600;
            color: var(--color-black) !important;
        }
    }

    :global(table) {
        white-space: unset;
    }
</style>
