<script lang="ts">
    import { userProfile } from '_auth/stores/auth.store'
    import { MaterialIcon } from '_config/enums/material-icon.enum'
    import { getAuctionsByDate } from 'helpers/auctions.helper'
    import Auction from 'modules/auctions/auction-item.svelte'
    import { auctionFilter, auctionsSubscription, maxAuctionNumber, activeSubscription, requestAuctionsFromPage } from 'stores/auction.store'
    import { onDestroy } from 'svelte'
    import IconButton from 'components/form/icon-button.svelte'
    import { goto } from '$app/navigation'
    import { getAddDaysUTC, getTodayUTC } from 'helpers/date.helper'
    import * as dateFns from 'date-fns'
    import { getAuctionForHomepage } from '_data/_api/auctions/auction.svelte'
    import { getClient } from '@urql/svelte'
    import { browser } from '$app/env'

    let auctionsGroupByDate: AuctionDetailByDate[]
    const client = getClient()

    const format: AuctionDateFormat = {
        today: "'Today ('dd MMM')'",
        upcoming: 'dd MMM ',
    }

    if (browser) {
        $requestAuctionsFromPage = 'home'

        $auctionFilter = {
            from: getTodayUTC().toISOString(),
            until: dateFns.endOfDay(getAddDaysUTC(1)).toISOString(),
            auctionContentType: 0,
            pageSize: $maxAuctionNumber,
        } as AuctionFilter

        getAuctionForHomepage($auctionFilter, client)
    }

    onDestroy(() => {
        $auctionsSubscription = null
        $activeSubscription?.unsubscribe()
    })

    function handleClick() {
        return goto(`/auctions/upcoming`)
    }

    function getAuctionsGroupByDate() {
        auctionsGroupByDate = getAuctionsByDate($auctionsSubscription, format)
    }

    $: if ($auctionsSubscription) getAuctionsGroupByDate()
    $: if ($userProfile && $auctionFilter && $requestAuctionsFromPage == 'home') getAuctionForHomepage($auctionFilter, client)
</script>

<section>
    {#if auctionsGroupByDate}
        {#if auctionsGroupByDate?.length == 0}
            <h3>
                <span>There are currently no upcoming auctions. Please check back again soon.</span>
            </h3>
        {:else}
            {#each auctionsGroupByDate as auctions}
                {#if auctions.auctions.length > 0}
                    <div>{auctions.auctionDateDisplay}</div>
                    {#each auctions.auctions as auction}
                        <Auction {auction} />
                    {/each}
                {/if}
            {/each}
        {/if}
    {/if}
    <div class="view-all-auctions">
        <IconButton label="VIEW ALL AUCTIONS" icon={MaterialIcon.ArrowRight} on:click={handleClick} />
    </div>
</section>

<style lang="scss">
    section {
        display: grid;
        max-width: 60vw;
        grid-gap: var(--grid-gap);

        @media (max-width: 1024px) {
            max-width: unset;
            grid-gap: 0.5rem;
        }

        div:not(.view-all-auctions) {
            margin: 0.2rem 0 -0.8rem;

            font-weight: 800;
            font-size: 2rem;
            line-height: 3.2rem;
        }

        .view-all-auctions {
            width: 50%;
            padding: 1rem 0;
            justify-self: center;

            @media (max-width: 1024px) {
                width: calc(100% - 2rem);
            }
            @media (max-width: 365px) {
                :global(span) {
                    font-size: 1.2rem;
                }
                :global(i) {
                    font-size: 2rem;
                }
            }
        }
    }
</style>
