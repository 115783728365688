<script lang="ts">
    import Button from '@cucumber/ui/components/buttons/button.svelte'

    import { createEventDispatcher } from 'svelte'

    export let primary = true

    export let icon: string = ''

    export let size = 'medium'

    export let label = ''

    export let style = 'contained' //outlined

    const dispatch = createEventDispatcher()

    function onClick(event: any) {
        dispatch('click', event)
    }
</script>

<div>
    <Button {label} {style} {primary} {size} label_position="left" on:click={onClick}>
        <img src="/assets/images/keyboard_arrow_right.svg" alt="keyboard-arrow-right" />
    </Button>
</div>

<style lang="scss">
    div {
        --line-height: 4rem;
        --font-weight: 800;

        img {
            float: right;
            width: 1.6rem;
            transform: translateY(11px);
        }
    }
</style>
