<script lang="ts">
    export let title: Maybe<string>

    import Icon from '@cucumber/ui/components/icon.svelte'

    let drawerOpen = false

    function onClick(event: any): void {
        drawerOpen = !drawerOpen
    }
</script>

<section>
    <div class="container" on:click={onClick} class:open={drawerOpen}>
        <div class="title">
            <div><h3>{title}</h3></div>
            <Icon icon={'keyboard_arrow_down'} />
        </div>
        <div class="inner">
            <b>
                <slot />
            </b>
        </div>
    </div>
</section>

<style lang="scss">
    section {
        background: var(--component-background, transparent);

        h3 {
            color: var(--color-secondary);
            padding-top: unset;
        }

        div {
            &:hover {
                cursor: pointer;
            }
            grid-row: 1;
        }

        .title {
            display: grid;
            grid-template-columns: 1fr max-content;
            align-items: center;
        }

        .inner {
            b {
                transition: none;

                position: relative;
                height: 0;
                opacity: 0;
                overflow: hidden;
                width: 100%;
            }
        }

        .container {
            grid-column: 2/8;
            border-bottom: 1px solid var(--color-grey);
            @media (min-width: 769px) {
                grid-column: span 12;
            }

            &.open {
                padding-bottom: 7rem;

                b {
                    transition: opacity 500ms ease-in-out;
                    opacity: 1;

                    height: 100%;
                    padding: 0 1rem 1rem 1rem;
                    margin-bottom: -7rem;
                    padding-left: 1rem;
                }

                .title {
                    :global(i::after) {
                        transform: rotate(-180deg);
                    }
                }
            }
        }
    }
</style>
