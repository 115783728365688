<script lang="ts">
    export let data: UpcomingAuction[]
    export let recordsPerPage: number
    export let showRecords: UpcomingAuction[] | undefined
    import Button from '@cucumber/ui/components/buttons/button.svelte'

    let currentPage = 0

    $: totalRows = data.length
    $: totalPages = Math.ceil(totalRows / recordsPerPage)
    $: start = currentPage * recordsPerPage
    $: end = currentPage === totalPages - 1 ? totalRows - 1 : start + recordsPerPage - 1

    $: showRecords = data.slice(start, end + 1)

    $: totalRows, (currentPage = 0)
    $: currentPage, start, end

    function prev() {
        currentPage -= 1
        scroll(0, 0)
    }

    function next() {
        currentPage += 1
        scroll(0, 0)
    }
</script>

{#if totalRows && totalRows > recordsPerPage}
    <div class="pagination">
        <Button label="Previous" style="outlined" on:click={prev} disabled={currentPage === 0 ? true : false} />
        <p>{currentPage + 1} of {totalPages}</p>
        <Button label="Next" on:click={next} disabled={currentPage === totalPages - 1 ? true : false} />
    </div>
{/if}

<style lang="scss">
    .pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: all;

        p {
            margin: 0 1rem;
        }
    }
</style>
