<script lang="ts">
    export let rawData: Maybe<UpcomingAuction[]>
    export let filteredAuctions: UpcomingAuction[] | undefined
    export let showPastDate: boolean = false
    export let defaultDateRange: Date[] = []
    export let datePickerOnChange: boolean = false
    export let selectedDates: string | Date | Date[] | undefined
    export let selectedDatesUTC: string[]

    import DropDownSingle from 'components/form/dropdown-single.svelte'
    import DropDownMultiple from 'components/form/dropdown-multiple.svelte'
    import DatePicker from '@cucumber/ui/components/forms/datepicker.svelte'
    import { browser } from '$app/env'
    import { getEndOfDayTime } from 'helpers/date.helper'
    import * as dateFns from 'date-fns'
    import { maxFilterDateRange } from 'stores/auction.store'

    let selectedSaleFormat = ''
    let selectedStockTypes: Option[] | undefined = []
    let auctionFormatOptions: Option[] | undefined = []
    let stockTypesOptions: Option[] | undefined = []

    const ddlOptionForALL = { label: 'All', value: '' }

    if (browser) {
        selectedDates = defaultDateRange

        datePickerOnChange = true

        prepareSaleFormatOptions()
        prepareStockTypesOptions()
    }

    function prepareSaleFormatOptions() {
        auctionFormatOptions = [ddlOptionForALL, { label: 'Saleyard', value: 'Saleyard' }, { label: 'Not Saleyard', value: 'Not-saleyard' }]
    }

    function prepareStockTypesOptions() {
        const stockTypes = ['All', 'Dairy', 'Dairy Beef', 'Beef', 'Sheep', 'Other', 'Saleyard Cattle', 'Saleyard Sheep']
        stockTypesOptions = stockTypes.map(stockType => {
            if (stockType == 'All') return ddlOptionForALL
            else return { label: stockType, value: stockType, disabledTitle: 'disabled', preselected: true }
        })

        selectedStockTypes = [ddlOptionForALL]
    }

    function filterAuctions() {
        filteredAuctions = rawData as UpcomingAuction[]

        if (selectedStockTypes?.length) {
            if (selectedStockTypes[selectedStockTypes?.length - 1].value == '') {
                selectedStockTypes = [ddlOptionForALL]
            } else {
                selectedStockTypes = selectedStockTypes.filter(stockType => stockType.value != '')
            }

            if (!selectedStockTypes.find(stockType => stockType.value == '')) {
                filteredAuctions = filteredAuctions?.filter(item => selectedStockTypes?.some(selected => item.stockTypes.includes(selected.value)))
            }
        } else {
            selectedStockTypes = [ddlOptionForALL]
        }

        if (selectedSaleFormat) {
            filteredAuctions = filteredAuctions?.filter(
                item =>
                    (selectedSaleFormat.toUpperCase() === 'SALEYARD' && item.auctionFormatLabel.toUpperCase() === selectedSaleFormat.toUpperCase()) ||
                    (selectedSaleFormat.toUpperCase() !== 'SALEYARD' && item.auctionFormatLabel.toUpperCase() !== 'SALEYARD')
            )
        }
    }

    function handleSelectedDateChanged() {
        if (selectedDates && (selectedDates as Date[]).length > 1) {
            let rangeStart = (selectedDates as Date[])[0]
            let rangeEnd = (selectedDates as Date[])[1]

            selectedDatesUTC = [rangeStart.toISOString(), getEndOfDayTime(dateFns.addDays(rangeEnd, 1)).toISOString()]
        }

        datePickerOnChange = false
    }

    $: if (rawData) {
        filterAuctions()
    }

    $: if (selectedSaleFormat || selectedStockTypes) filterAuctions()

    $: if (datePickerOnChange && selectedDates) handleSelectedDateChanged()
</script>

<section>
    <div class="column">
        <div class="filter-label">Stock Type:</div>
        <div class="multi-select">
            <DropDownMultiple options={stockTypesOptions} bind:selected={selectedStockTypes} />
        </div>
    </div>
    <div class="column">
        <div class="filter-label">Sale Format:</div>
        <div>
            {#if auctionFormatOptions}
                <DropDownSingle options={auctionFormatOptions} bind:value={selectedSaleFormat} />
            {/if}
        </div>
    </div>

    <div class="column">
        <div class="filter-label">Date:</div>
        <div class="datepicker"><DatePicker bind:selectedDates bind:datePickerOnChange {showPastDate} maxDateRangeAllowed={$maxFilterDateRange} /></div>
    </div>
</section>

<style lang="scss">
    section {
        --border-color: var(--color-accent);
        --color-font: var(--color-accent);

        display: grid;
        grid-template-columns: repeat(2, max-content) 1fr;
        align-items: center;
        grid-gap: 3rem;
        font-size: var(--font-size);
        max-width: 50%;

        @media (max-width: 720px) {
            grid-template-columns: unset;
            grid-gap: 0.5rem;

            .multi-select {
                :global(.multiselect) {
                    margin: 0;
                }
            }
        }

        .column {
            display: grid;

            .filter-label {
                font-weight: 700;
                padding-right: 3rem;
                color: var(--color-accent);
            }

            .datepicker {
                min-width: 23rem;
            }
        }
    }
</style>
