<script lang="ts">
    import { getAuctionsByDate } from 'helpers/auctions.helper'
    import Auction from 'modules/auctions/auction-item.svelte'
    import { auctionFilter, auctionsNoSubscription, defaultResultFilterDateRange, requestAuctionsFromPage } from 'stores/auction.store'
    import { onDestroy } from 'svelte'
    import { getAddDaysUTC, getTodayUTC } from 'helpers/date.helper'
    import Pagination from 'modules/auctions/auction-pagination.svelte'
    import AuctionFilterComponent from 'modules/auctions/auction-filter.svelte'
    import { getClient } from '@urql/svelte'
    import { getPastAuctions } from '_data/_api/auctions/auction.svelte'
    import { browser } from '$app/env'

    const needSortByDate = false

    const format: AuctionDateFormat = {
        today: "'Today ('dd MMMM yyyy')'",
        upcoming: "EEEE',' dd MMMM yyyy ",
    }

    let auctionsGroupByDate: AuctionDetailByDate[]

    let showRecords: UpcomingAuction[]
    let filteredAuctions: UpcomingAuction[]

    let recordsPerPage = 10
    let selectedDatesUTC: string[]
    let datePickerOnChange: boolean
    let selectedDates: string | Date | Date[] | undefined
    const client = getClient()

    if (browser) {
        $requestAuctionsFromPage = 'result'
    }

    onDestroy(() => {
        $auctionFilter = null
    })

    function filterAuctions() {
        if (filteredAuctions) getLatestAuctionsGroup()
    }

    function triggerAPIRequest(from: string, until: string) {
        $auctionFilter = {
            from: from,
            until: until,
            auctionContentType: 0,
        } as AuctionFilter

        getPastAuctions($auctionFilter, client)
    }

    function handleSelectedDateChanged() {
        if (selectedDatesUTC && selectedDatesUTC.length > 1) {
            triggerAPIRequest(selectedDatesUTC[0], selectedDatesUTC[1])
        }
    }

    function getLatestAuctionsGroup() {
        auctionsGroupByDate = getAuctionsByDate(showRecords, format, needSortByDate)
    }

    $: if (showRecords) getLatestAuctionsGroup()
    $: if (filteredAuctions) filterAuctions()

    $: if (selectedDatesUTC) handleSelectedDateChanged()
</script>

<section>
    <AuctionFilterComponent
        rawData={$auctionsNoSubscription}
        showPastDate={true}
        bind:filteredAuctions
        bind:selectedDates
        bind:selectedDatesUTC
        bind:datePickerOnChange
        defaultDateRange={[getAddDaysUTC(-1 * $defaultResultFilterDateRange), getTodayUTC()]}
    />

    {#if filteredAuctions}
        {#if filteredAuctions?.length == 0}
            <h3>
                <span> There are no auctions to display, please check the date range selected. </span>
            </h3>
        {:else if auctionsGroupByDate}
            {#each auctionsGroupByDate as auctions}
                {#if auctions?.auctions?.length > 0}
                    <div>{auctions?.auctionDateDisplay}</div>
                    {#each auctions?.auctions as auction}
                        <Auction {auction} />
                    {/each}
                {/if}
            {/each}
        {/if}
        <Pagination data={filteredAuctions} {recordsPerPage} bind:showRecords />
    {/if}
</section>

<style lang="scss">
    section {
        display: grid;
        grid-gap: var(--grid-gap);

        @media (max-width: 1024px) {
            max-width: unset;
            grid-gap: 0.5rem;
        }

        div {
            padding: 1.5rem 1rem 0.7rem;

            font-weight: 800;
            font-size: 2rem;
            line-height: 3.2rem;
        }
    }
</style>
