<script lang="ts">
    export let title: Maybe<string>
    export let body: Maybe<string>
    export let image: Maybe<string>
    export let link: Maybe<Link>
    export let link_file: Maybe<Link_File>
    export let name: Maybe<string>
    export let dark_mode: Maybe<boolean>

    import { getDirectusAssetLink } from '@cucumber/ui/helpers/string'
    import LinkWidget from '@cucumber/ui/components/widgets/link.svelte'
    import LinkFile from '@cucumber/ui/components/widgets/link-file.svelte'

    $: hasLinkBanner = link?.display == 'banner' || link_file?.display == 'banner'
    $: hasLinkButton = link?.display == 'button' || link_file?.display == 'button'
</script>

<section {name} class="grid-wrapper" class:dark_mode_true={dark_mode} class:hasLinks={hasLinkBanner || hasLinkButton}>
    <img src={getDirectusAssetLink(image)} alt={title ?? ''} />
    <b>
        {#if title}
            <h2>{title}</h2>
        {:else}
            <div />
        {/if}

        {#if body}
            <p>{body}</p>
        {:else}
            <div />
        {/if}

        {#if link?.display == 'default' || link_file?.display == 'default'}
            {#if link}
                <LinkWidget dataStore={link} />
            {:else if link_file}
                <LinkFile dataStore={link_file} />
            {/if}
        {/if}
        {#if hasLinkButton}
            <div class="link-button">
                {#if link}
                    <LinkWidget dataStore={link} />
                {:else if link_file}
                    <LinkFile dataStore={link_file} />
                {/if}
            </div>
        {/if}
        {#if hasLinkBanner}
            <div class="link-banner">
                {#if link}
                    <LinkWidget dataStore={link} />
                {:else if link_file}
                    <LinkFile dataStore={link_file} />
                {/if}
            </div>
        {/if}
    </b>
</section>

<style lang="scss">
    section.grid-wrapper {
        width: 100%;
        height: 100%;

        background-size: cover;

        grid-template-rows: min-content;
        display: grid;

        &.hasLinks {
            // grid-template-rows: min-content min-content;
            grid-template-rows: 1fr;
        }

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
            grid-row: 1;
            grid-column: 1;
            z-index: 1;
        }

        b {
            display: grid;
            grid-template-rows: min-content 1fr min-content;
            grid-row: 1;
            grid-column: 1;
            z-index: 2;
        }

        title,
        h2,
        p {
            padding: 2rem 3rem 0 3rem;
        }

        .link-button {
            width: 100%;
            bottom: 0;
            z-index: 4;
        }

        .link-banner {
            z-index: 3;

            :global(.link-file) {
                bottom: 0;
                margin-bottom: 0;
            }

            :global(section) {
                margin-bottom: 0;
                margin: 0;
            }

            :global(button) {
                padding: 0.3rem 1.8rem;
            }

            :global(p) {
                width: unset;
            }

            :global(i) {
                color: var(--color-white);
            }

            :global(a) {
                color: var(--color-white);
            }
        }

        &.dark_mode_true {
            p,
            h2,
            :global(a) {
                color: var(--color-white);
            }
        }
    }
</style>
