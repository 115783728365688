import FAQCollection from '@cucumber/ui/components/widgets/collections/faq.svelte'
import WalkthroughLinksCollection from '@cucumber/ui/components/widgets/collections/walkthrough-links.svelte'
import TeamCollection from '@cucumber/ui/components/widgets/collections/team.svelte'
import TileSetsCollection from 'components/widgets/tile-sets/tile-sets.svelte'

import AddressWidget from '@cucumber/ui/components/widgets/address.svelte'
import BannerWidget from '@cucumber/ui/components/widgets/banner.svelte'
import BuilderBlockWidget from '@cucumber/ui/components/widgets/_builder-block.svelte'
import DividerWidget from '@cucumber/ui/components/widgets/divider.svelte'
import LinkWidget from '@cucumber/ui/components/widgets/link.svelte'
import LinkFileWidget from '@cucumber/ui/components/widgets/link-file.svelte'
import MediaWidget from '@cucumber/ui/components/widgets/media.svelte'
import PhoneNumberWidget from '@cucumber/ui/components/widgets/phone-number.svelte'
import PlainTextWidget from '@cucumber/ui/components/widgets/plain-text.svelte'
import QuestionWidget from '@cucumber/ui/components/widgets/question.svelte'
import RichTextWidget from '@cucumber/ui/components/widgets/rich-text.svelte'
import SpacerWidget from '@cucumber/ui/components/widgets/spacer.svelte'
import StoryWidget from '@cucumber/ui/components/widgets/story.svelte'
import TeamMemberWidget from '@cucumber/ui/components/widgets/team-member.svelte'
import TileWidget from '@cucumber/ui/components/widgets/tile.svelte'
import TitleWidget from '@cucumber/ui/components/widgets/title.svelte'
import AuctionsUpcomingHome from 'modules/auctions/collections/auctions-upcoming-home.svelte'
import AuctionsUpcomingAll from 'modules/auctions/collections/auctions-upcoming-all.svelte'
import AuctionsResult from 'modules/auctions/collections/auctions-result.svelte'
import AuctionsFilter from 'modules/auctions/auction-filter.svelte'
import Millicast from 'modules/millicast/millicast.svelte'

export const components = new Map()

//Collections
components.set('faq', FAQCollection)
components.set('walkthrough_links', WalkthroughLinksCollection)
components.set('team', TeamCollection)
components.set('tile_sets', TileSetsCollection)

//Custom
components.set('auctions-upcoming-home', AuctionsUpcomingHome)
components.set('auctions-upcoming-all', AuctionsUpcomingAll)
components.set('auctions-filter', AuctionsFilter)
components.set('auctions-result', AuctionsResult)
components.set('millicast', Millicast)

//Widgets
components.set('address', AddressWidget)
components.set('banner', BannerWidget)
components.set('divider', DividerWidget)
components.set('link', LinkWidget)
components.set('link_file', LinkFileWidget)
components.set('media', MediaWidget)
components.set('phone_number', PhoneNumberWidget)
components.set('plain_text', PlainTextWidget)
components.set('question', QuestionWidget)
components.set('rich_text', RichTextWidget)
components.set('spacer', SpacerWidget)
components.set('story', StoryWidget)
components.set('team_member', TeamMemberWidget)
components.set('tile', TileWidget)
components.set('title', TitleWidget)

//Other
components.set('builder_block', BuilderBlockWidget)
