<script lang="ts">
    export let title: Maybe<string>
    export let body: Maybe<string>
    export let image: Maybe<string>
    export let link: Maybe<Link>
    export let name: Maybe<string>

    import { getDirectusAssetLink } from '@cucumber/ui/helpers/string'
    import LinkWidget from '@cucumber/ui/components/widgets/link.svelte'
</script>

<section {name}>
    <div class="flip-card">
        <div class="flip-card-inner">
            <div class="flip-card-front">
                <img src={getDirectusAssetLink(image)} alt={title} />
            </div>
            <div class="flip-card-back">
                <b>
                    {#if title}
                        <h3>{title}</h3>
                    {/if}

                    {#if body}
                        <p>{body}</p>
                    {/if}
                </b>

                {#if link}
                    <LinkWidget dataStore={link} />
                {/if}
            </div>
        </div>
    </div>
</section>

<style lang="scss">
    section {
        background: var(--component-background, transparent);

        width: 100%;
        height: 100%;

        .flip-card {
            background-color: transparent;
            width: 100%;
            height: 100%;

            perspective: 1000px;

            /* This container is needed to position the front and back side */
            .flip-card-inner {
                position: relative;
                width: 100%;
                height: 100%;
                text-align: center;
                transition: transform 0.8s;
                transform-style: preserve-3d;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &:hover {
                .flip-card-inner {
                    transform: rotateY(180deg);
                }
            }

            /* Position the front and back side */
            .flip-card-front,
            .flip-card-back {
                position: absolute;
                width: 100%;
                height: 100%;
                -webkit-backface-visibility: hidden; /* Safari */
                backface-visibility: hidden;
            }

            /* Style the front side (fallback if image is missing) */
            .flip-card-front {
                background-color: #bbb;
                color: black;
                height: 100%;
            }

            /* Style the back side */
            .flip-card-back {
                transform: rotateY(180deg);
                display: grid;
                grid-template-rows: 1fr min-content;
                // border: 1px solid var(--border-color, #3f7e44);

                :global(a) {
                    color: var(--color-accent);
                    font-size: 1.2rem;
                }
                &::before {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    display: block;
                }
                div {
                    background-color: lightgrey;
                    height: 3rem;
                    width: 100%;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    text-align: center;
                    padding: 0.5rem;
                }

                p {
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }
        }
    }
</style>
