<script lang="ts">
    export let dataStore: Media
    export let first: boolean = false

    import { getDirectusAssetLink } from '@cucumber/ui/helpers/string'
    import YoutubeVideo from '@cucumber/ui/components/widgets/parts/video-youtube.svelte'
    import RichTextWidget from '@cucumber/ui/components/widgets/rich-text.svelte'

    $: richTextProps = {
        name: dataStore?.name,
        value: dataStore?.body,
        status: dataStore?.status as string,
    }
</script>

{#if dataStore?.status == 'published'}
    {#if dataStore.body}
        <section name={dataStore?.name} class:fullWidth={dataStore.display == 'text-only'} data-widget={dataStore.__typename} class:first>
            <div class={['media', dataStore?.asset_position].join(' ')}>
                {#if dataStore.display == 'image'}
                    <img src={getDirectusAssetLink(dataStore?.image?.filename_disk)} alt={dataStore.name} />
                {/if}
                {#if dataStore.display == 'video'}
                    <YoutubeVideo videoId={dataStore?.video} />
                {/if}
                {#if dataStore.display == 'video' && !dataStore?.video}
                    <img src="/assets/images/media-fall-back-image.png" alt={dataStore?.name} />
                {/if}
                {#if dataStore?.caption && dataStore.display != 'text-only'}
                    <div class="caption">
                        <p>{dataStore?.caption}</p>
                    </div>
                {/if}
            </div>

            {#if !dataStore?.caption}
                <div class="rich-text" style="margin-bottom: 0 !important;">
                    <RichTextWidget dataStore={richTextProps} />
                </div>
            {:else}
                <div class="rich-text">
                    <RichTextWidget dataStore={richTextProps} />
                </div>
            {/if}
        </section>
    {/if}

    {#if !dataStore.body}
        <section name={dataStore?.name} class="image-video-only">
            <div class={['media', dataStore?.asset_position].join(' ')}>
                {#if dataStore.display == 'image'}
                    <img class="image-only" src={getDirectusAssetLink(dataStore?.image?.filename_disk)} alt="test" />
                {:else if dataStore.display == 'video'}
                    <YoutubeVideo videoId={dataStore?.video} />
                {/if}
                {#if dataStore?.caption}
                    <div class="caption">
                        <p>{dataStore?.caption}</p>
                    </div>
                {/if}
            </div>
        </section>
    {/if}
{/if}

<style lang="scss">
    section {
        background: var(--component-background, transparent);
        max-width: var(--bp-max);

        display: grid;
        position: relative;
        align-items: center;

        grid-template-rows: 2;

        .caption {
            p {
                font-weight: 300;
                opacity: 50%;
                margin: 0;
                font-size: 1.4rem;
                font-family: monospace, monospace;
            }
        }

        .media {
            line-height: 0;
        }

        img {
            width: 100%;
            object-fit: cover;
            aspect-ratio: 16 / 9;
            grid-area: img;
        }

        .image-only {
            object-fit: contain;
            aspect-ratio: unset;
            max-width: 100%;
            margin: 0 auto;
            display: block;
            width: unset;
        }
    }

    @media (min-width: 1000px) {
        section {
            grid-template-columns: 1fr 1fr;
            grid-gap: 4rem;

            &.fullWidth {
                grid-template-columns: unset;
            }
            .media {
                line-height: 0;
                grid-row: 1;

                &.left {
                    grid-column: 1;
                }

                &.right {
                    grid-column: 2;
                }
            }

            .rich-text {
                margin-bottom: 4rem;
            }
        }

        .image-video-only {
            grid-template-columns: 1fr;
            margin: unset;
            div {
                :global(iframe) {
                    width: 100%;
                    margin: 0 auto;
                    display: block;
                }
                .caption {
                    text-align: center;
                }
            }

            .media {
                &.right {
                    grid-column: 1;
                }
            }
            img {
                object-fit: contain;
            }
        }
    }

    section {
        background: var(--component-background, transparent);
        max-width: var(--bp-max);

        display: grid;
        position: relative;
        align-items: center;

        grid-template-rows: 2;

        margin: 0 0 var(--grid-gap);

        .media {
            line-height: 0;
        }

        img {
            width: 100%;
            object-fit: cover;
            aspect-ratio: 16 / 9;
            grid-area: img;
        }

        .image-only {
            object-fit: contain;
            aspect-ratio: unset;
        }
    }
</style>
