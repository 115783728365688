<script lang="ts">
    export let content: Maybe<Page>
    export let components: Map<string, SvelteComponent>

    let component = components.get('banner')!
</script>

{#if content?.status == 'published'}
    <section name={content.name}>
        {#if content.header}
            <div class="page-header">
                <svelte:component this={component} dataStore={content.header} />
            </div>
        {/if}

        <div class="title-intro">
            {#if content.title}
                <h1>{content.title}</h1>
            {/if}

            {#if content.intro}
                <div class="intro">
                    {@html content.intro}
                </div>
            {/if}
        </div>

        <slot />

        <i>
            <div class="stage" class:fullWidth={!content.aside?.length}>
                {#if content.main}
                    <b class="main" class:fullWidth={!content.aside?.length}>
                        {#each content.main as sections, i}
                            {#if sections?.collection}
                                {#if sections?.collection == 'custom_marker'}
                                    {#if sections?.item}
                                        <svelte:component this={components.get(sections.item.name ?? '')} />
                                    {/if}
                                {:else}
                                    <svelte:component this={components.get(sections?.collection)} dataStore={sections?.item} first={i === 0} />
                                {/if}
                            {/if}
                        {/each}
                    </b>
                {/if}

                {#if content.aside && content.aside.length > 0}
                    <b class="aside">
                        {#each content.aside as sections, i}
                            {#if sections?.collection}
                                <svelte:component this={components.get(sections?.collection)} dataStore={sections?.item} first={i === 0} />
                            {/if}
                        {/each}
                    </b>
                {/if}
            </div>
        </i>

        <i>
            <div class="stage" class:fullWidth={!content.secondary_aside?.length}>
                {#if content.secondary_main}
                    <b class="main" class:fullWidth={!content.secondary_aside?.length}>
                        {#each content.secondary_main as sections, i}
                            {#if sections?.collection}
                                {#if sections?.collection == 'custom_marker'}
                                    {#if sections?.item}
                                        <svelte:component this={components.get(sections.item.name ?? '')} />
                                    {/if}
                                {:else}
                                    <svelte:component this={components.get(sections?.collection)} dataStore={sections?.item} />
                                {/if}
                            {/if}
                        {/each}
                    </b>
                {/if}

                {#if content.secondary_aside && content.secondary_aside.length > 0}
                    <b class="aside">
                        {#each content.secondary_aside as sections, i}
                            {#if sections?.collection}
                                <svelte:component this={components.get(sections?.collection)} dataStore={sections?.item} />
                            {/if}
                        {/each}
                    </b>
                {/if}
            </div>
        </i>
    </section>
{/if}

<style lang="scss">
    section {
        background: var(--component-background, transparent);
        max-width: var(--bp-max);

        .page-header,
        .intro {
            margin-bottom: 3rem;
        }

        .title-intro {
            padding: 0 5rem;
            @media (max-width: 1024px) {
                padding: 0 2rem;
            }
        }

        .stage {
            display: grid;
            grid-gap: var(--grid-gap);
            padding: 0 5rem;
            justify-items: center;

            @media (max-width: 1024px) {
                padding: 0 2rem;
            }

            &:not(.fullWidth) {
                grid-template-columns: 2fr 1fr;

                @media (max-width: 1024px) {
                    grid-template-columns: unset;
                }
            }

            b {
                display: grid;
                grid-template-columns: 1fr;
                grid-auto-rows: min-content;
                margin-bottom: 1rem;
                width: 100%;

                &:first-child:not(.fullWidth) {
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-auto-rows: min-content;
                }

                &.aside {
                    display: block;

                    :global(.tile_set) {
                        grid-template-columns: 1fr !important;
                    }
                }
            }
        }
    }
</style>
