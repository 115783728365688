<script lang="ts">
    export let name: Maybe<string>
    export let account: Maybe<string>
    export let stream: Maybe<string>
</script>

<section>
    {#if stream && account}
        <iframe src={`https://viewer.millicast.com?streamId=${account}/${stream}`} title={name} allowfullscreen />
    {/if}
</section>

<style lang="scss">
    section {
        background: var(--component-background, transparent);
        line-height: 0;

        iframe {
            margin: 0 auto;
            display: block;
            aspect-ratio: 16/9;
            width: 100%;
            height: 100%;
        }
    }
</style>
