<script lang="ts">
    export let options: Option[] | undefined
    export let selected: Option[] | undefined

    import MultiSelect from 'svelte-multiselect'
</script>

{#if options && options.length > 0}
    <div class="multiselect-wrapper">
        <MultiSelect bind:selected {options} />
    </div>
{/if}

<style lang="scss">
    .multiselect-wrapper {
        width: 20rem;

        > :global(div) {
            min-height: 3.1rem;
            border: 1px solid var(--color-accent);
            margin: 0;
        }

        :global(input) {
            padding: 0.4rem 0.8rem;
        }

        :global(svg) {
            fill: var(--color-accent);
        }

        :global(div.multiselect > ul.selected > li) {
            background-color: var(--color-accent);
            color: var(--color-white);
        }

        :global(div.multiselect > ul.selected > li svg) {
            fill: currentColor;
        }
    }
</style>
