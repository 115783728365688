<script lang="ts">
    export let dataStore: Plain_Text
    export let first: boolean = false
</script>

{#if dataStore?.value && dataStore?.status == 'published'}
    <section class={[`${dataStore?.position}`, `${dataStore?.type}`].join(' ')} name={dataStore?.name} data-widget={dataStore.__typename} class:first>
        {@html dataStore?.value}
    </section>
{/if}

<style lang="scss">
    section {
        background: var(--component-background, transparent);
        max-width: var(--bp-max);

        font-weight: normal;
        font-size: var(--font-size);
        padding: var(--text-padding);
        color: black;

        :global(a) {
            color: var(--color-link-blue);
        }

        &.left {
            text-align: left;
        }

        &.right {
            text-align: right;
        }

        &.centre {
            text-align: center;
        }

        &.justify {
            text-align: justify;
        }

        &.lead {
            font-weight: var(--font-weight-bold);
        }

        &.soft {
            color: var(--font-weight-soft);
        }
    }
</style>
